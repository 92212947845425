<template>
    <div class="row d-flex justify-content-center mb-5 mt-5">
        <div class="col-10 mb-5">
            <h1>Iscritti</h1>
        </div>
        
        <div class="col-10" >
            <div>
                <table class="table">
                    <thead>
                        <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nome</th>
                        <th scope="col">Cognome</th>
                        <th scope="col">C. Fiscale</th>
                        <th scope="col">Data Nascita</th>
                        <th scope="col">Citta</th>
                        <th scope="col">Best Truck</th>
                        <th scope="col">Gran Prix</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(competitor, index) in this.competitors" :key="index">
                        <th scope="row">{{competitor.id}}</th>
                        <td>{{competitor.name}}</td>
                        <td>{{competitor.lastname}}</td>
                        <td>{{competitor.cf}}</td>
                        <td>{{competitor.bDate}}</td>
                        <td>{{competitor.country}}</td>
                        <td>{{competitor.bTruck}}</td>
                        <td>{{competitor.gPrix}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

import API from "@/api.js";


export default {
  
components: { },

  data () {
    return {
       
      competitors:[],
    }
  },
  mounted() {
    // Set the initial number of items
    console.log(API);
    this.axios.get(API.getCompetitors.path()).then(response => {
      this.competitors=response.data;
      console.log(response)

    }).catch(error=>{
      console.log(error)

    });

  },
}



</script>
